import React, { useEffect } from "react"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  let header
  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  })

  header = (
    <h3
      style={{
        fontFamily: `Montserrat, sans-serif`,
        marginTop: 0,
        "font-size": "15px",
      }}
    >
      <Link
        style={{
          boxShadow: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
        <div style={{ display: "inline-block" }}>
          <div>今日勉強したことを</div>
          <div>つらつらと</div>
        </div>
        <div style={{ display: "inline-block" }}>
          <img
            src="/icons/icon-48x48.png"
            alt="logo"
            style={{
              margin: "0 5px",
              height: "30px",
            }}
          />
        </div>
      </Link>
    </h3>
  )
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()} simodake
        <ins
          className="adsbygoogle"
          data-ad-client="ca-pub-5294124583770457"
          data-ad-slot="6981588629"
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      </footer>
    </div>
  )
}

export default Layout
